"use client"

import React, { useContext } from "react"

import Image, { type StaticImageData, type ImageProps } from "next/image"

import { DMThemeGenerator } from "@supernovaio/dm"

type Props = {
  src: StaticImageData
  srcDark?: StaticImageData
  className?: string
  width?: ImageProps["width"]
  height?: ImageProps["height"]
  priority?: ImageProps["priority"]
  style?: ImageProps["style"]
}

export function Illustration({
  src,
  srcDark,
  className,
  width,
  height,
  priority,
  style,
}: Props) {
  const { luminanceBackgroundColor } = useContext(DMThemeGenerator) || {}
  const currentSrc =
    luminanceBackgroundColor === "dark" && srcDark ? srcDark : src

  return (
    <Image
      src={currentSrc}
      className={className}
      width={width || src.width}
      height={height || src.height}
      priority={priority}
      alt=""
      style={style}
    />
  )
}
