"use client"

import React, {
  HTMLProps,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"

import { cn } from "@supernovaio/dm/src/utils/cn"

import { SegmentedInputChildModifier } from "../../DMSegmentedInputWrapper"
import { InputBaseProps } from "../InputBase"
import SelectOnFocusInputWrapper, {
  SelectOnFocusInputWrapperProps,
} from "../SelectOnFocusInputWrapper/SelectOnFocusInputWrapper"

import { cva, VariantProps } from "class-variance-authority"

const focusInputWrapperVariants = cva([""], {
  variants: {
    variant: {
      outline: "",
    },
    disabled: {
      true: "",
      false: "",
    },
    readOnly: {
      true: "",
      false: "",
    },
    size: {
      small: "h-form-small",
      medium: "h-form-medium",
      large: "h-form-large",
    },
    hasError: {
      true: "",
      false: "",
    },
  },
  compoundVariants: [
    // Disabled state:
    {
      variant: "outline",
      disabled: true,
      readOnly: false,
      hasError: [true, false],
      class:
        "bg-neutral opacity-disabled inner-border-neutral cursor-not-allowed",
    },
    // Normal state:
    {
      variant: "outline",
      disabled: false,
      readOnly: false,
      hasError: false,
      class: "bg-elevation-base inner-border-neutral-faded hover:inner-border-neutral cursor-text",
    },
    // Read-only state:
    {
      variant: "outline",
      disabled: false,
      readOnly: true,
      hasError: [true, false],
      class: "bg-neutral cursor-text",
    },
    // Common border classes:
    {
      variant: ["outline"],
      disabled: [true, false],
      readOnly: false,
      hasError: [true, false],
      class: "rounded inner-border",
    },
    // Border classes for all read-only states:
    {
      variant: ["outline"],
      disabled: false,
      readOnly: true,
      hasError: [true, false],
      class: "rounded",
    },
    // Border classes for error read-only state:
    {
      variant: ["outline"],
      disabled: false,
      readOnly: true,
      hasError: true,
      class: "inner-border",
    },
    // Non-error border:
    {
      variant: ["outline"],
      disabled: [true, false],
      readOnly: false,
      hasError: false,
      class: "",
    },
    // Error border:
    {
      variant: ["outline"],
      disabled: false,
      readOnly: [true, false],
      hasError: true,
      class:
        "hover:inner-border-critical inner-border-critical focus-within:inner-border-critical focus-within:hover:inner-border-neutral",
    },
  ],
})

const useIsInputFocused = (
  inputRefObject: React.RefObject<HTMLInputElement>
) => {
  const [isInputFocused, setIsInputFocused] = useState(false)

  useEffect(() => {
    const handleFocus = () => {
      setIsInputFocused(true)
    }

    const handleBlur = () => {
      setIsInputFocused(false)
    }

    const inputElement = inputRefObject.current

    // Check if the input is already focused (e.g., via autoFocus)
    if (inputElement && inputElement === document.activeElement) {
      setIsInputFocused(true)
    }

    if (inputElement) {
      inputElement.addEventListener("focus", handleFocus)
      inputElement.addEventListener("blur", handleBlur)
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener("focus", handleFocus)
        inputElement.removeEventListener("blur", handleBlur)
      }
    }
  }, [inputRefObject])

  return { isInputFocused }
}

export type FocusInputWrapperProps = Pick<
  HTMLProps<HTMLDivElement>,
  "disabled" | "children" | "className"
> &
  Pick<InputBaseProps, "size"> &
  Omit<VariantProps<typeof focusInputWrapperVariants>, "size"> &
  Pick<SelectOnFocusInputWrapperProps, "selectOnFocus"> & {
    inputRefObject: React.RefObject<HTMLInputElement>
  } & {
    hideOutline: boolean
  }

function FocusInputWrapper({
  className,
  children,
  variant = "outline",
  disabled = false,
  readOnly = false,
  size,
  inputRefObject,
  hasError = false,
  selectOnFocus,
  hideOutline,
  ...props
}: FocusInputWrapperProps) {
  const innerDivRef = useRef<HTMLDivElement>(null)

  const memoizedOnClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      // Only focus the input if the div itself was clicked - ignore slots and other children.
      // TODO: support empty space next to slots
      if (
        event.target === innerDivRef.current &&
        inputRefObject &&
        "current" in inputRefObject
      ) {
        inputRefObject.current?.focus()
      }
    },
    [inputRefObject]
  )

  const { isInputFocused } = useIsInputFocused(inputRefObject)

  return (
    <div
      className={cn(
        "rounded",
        {
          "inner-border focus-within:inner-border-focus hover:focus-within:inner-border-focus focus-within:ring-2 focus-within:ring-offset-1 ring-focus-faded":
            isInputFocused && !hideOutline,
        },
        focusInputWrapperVariants({
          variant,
          disabled,
          readOnly,
          hasError,
        }),
        "z-[1] flex items-center transition",
        SegmentedInputChildModifier,
        className
      )}
      {...props}
    >
      <SelectOnFocusInputWrapper
        inputRefObject={inputRefObject}
        render={({ onFocus }) => (
          /* All interactivity and key events is supported by the child <input>, so we don't need to add anything to this <div>  */
          /* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events  */
          <div
            ref={innerDivRef}
            className={cn(
              "flex w-full items-center",
              focusInputWrapperVariants({ size }),
              {
                "pointer-events-none": disabled,
              }
            )}
            onClick={memoizedOnClick}
            onFocus={onFocus}
          >
            {children}
          </div>
        )}
        selectOnFocus={selectOnFocus}
      />
    </div>
  )
}

export default FocusInputWrapper
